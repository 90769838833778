<!-- 选择License账单 -->
<template>
  <el-card shadow="never">
    <!--    <deviceDefaultInfo v-if="deviceObj" class="mt-2" :deviceObj="deviceObj"></deviceDefaultInfo>-->
    <div class="my-4">
      <div class="font-bold py-4">请选择账单</div>
      <div>
        <el-select size="small" class="ml-2 w-6/12" v-model="billProjectId">
          <el-option
              v-for="(item, index) in licenseProjectList"
              :key="index"
              :label="item.projectName"
              :value="item.id"
              class="h-16 flex justify-between align-items-center"
              style="width: 100%"
          >
            <div class="text-sm font-bold flex flex-col leading-6 w-full">
              <div>项目名称：{{ item.projectName }}</div>
              <div
                  class="text-xs font-light flex flex-row justify-between align-items-center"
              >
                <div>单价：{{ item.unitPrice }}元</div>
                <div>
                  账单剩余可用：{{
                    item.quantity - item.assignNum
                  }}{{ item.unit }}
                </div>
              </div>
            </div>
          </el-option>
        </el-select>
      </div>
      <el-button
          class="w-3/6 mt-6"
          type="primary"
          @click="toStep"
          :disabled="!billProjectId"
      >确认账单信息，进入下一步
      </el-button>
    </div>
  </el-card>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">

export default {
  data () {
    return {
      licenseProjectList: [],
      billProjectId: ''
    }
  },
  watch: {},
  computed: {},
  methods: {
    getLicenseProjectList () {
      this.$api.bill.getLicenseProjectList({
        uniqueId: this.$route.query.uniqueId
      }).then(res => {
        this.licenseProjectList = res.data
      })
    },
    toStep () {
      const curBillObj = this.licenseProjectList.find(item => item.id === this.billProjectId)
      this.$emit('on-submit', {
        uniqueId: this.$route.query.uniqueId,
        curBillObj,
        clientId: this.$route.query.clientId
      })
    }
  },
  mounted () {
    this.getLicenseProjectList()
  }
}
</script>
