<!-- 给IMEI授权 -->
<template>
  <div>
    <div class="mb-4">
      <div class="flex justify-between">
        <el-button @click="reSelectBill" type="warning" size="small"
        >重新选择账单
        </el-button>
      </div>
    </div>
    <InfiniteScrollTable
        ref="ref_infinite_table"
        :scrollData="imeiData"
        :getMoreData="getMoreDataFn"
        :loadConfig="loadConfig"
        @setTableData="setTableDataFn"
    >
      <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          ref="elTable"
          v-loading="tableIsLoading"
      >
        <el-table-column prop="imei" label="IMEI">
          <template v-slot="{ row }">
            {{ row.imei }}
          </template>
        </el-table-column>
        <el-table-column width="80" label="#">
          <template v-slot="{ $index }">
            <el-button
                size="mini"
                type="danger"
                @click="removeImei($index)"
                icon="el-icon-delete"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </InfiniteScrollTable>
    <el-button
        type="primary"
        class="mt-4 w-32"
        @click="submitImei"
        :disabled="imeiData.length === 0"
    >保存
    </el-button>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      imeiData: [],
      tableData: [],
      loadConfig: {
        totalCount: 0,
        itemHeight: 65
      },
      selectOptionsProjectList: [],
      tableIsLoading: false
    }
  },
  props: {
    billInfoObj: {}
  },
  computed: {
    ...mapGetters(['clientDeviceListMultipleList'])
  },
  methods: {
    getMoreDataFn () {
    },
    setTableDataFn (arr) {
      this.tableData = arr
    },
    removeImei (index) {
      this.imeiData.splice(index, 1)
    },
    initImeiData () {
      this.clientDeviceListMultipleList.forEach(item => {
        this.imeiData.push({
          imei: item.imei
        })
      })
    },
    submitImei () {
      if (this.imeiData.length === 0) {
        this.$message.warning('请先添加IMEI')
        return
      }
      const submitImeiFn = () => {
        this.tableIsLoading = true
        this.$api.customerDevice.customerDeviceUpdateV2({
          uniqueId: this.billInfoObj.uniqueId,
          deviceType: this.billInfoObj.deviceType,
          deviceModel: this.billInfoObj.deviceModel,
          feeCycle: this.billInfoObj.curBillObj.feeCycle,
          billProjectId: this.billInfoObj.curBillObj.id,
          imeiList: this.imeiData.map(item => item.imei)
        }).then(() => {
          this.$message.success('保存成功')
          this.$router.go(-1)
        })
      }
      this.$confirm('重新添加授权将会以当前开始计时，且原有授权账单数量不会进行释放，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        submitImeiFn()
      }).catch(() => {
      })
    },
    reSelectBill () {
      this.$emit('on-re-select-bill')
    }
  },
  async mounted () {
    this.tableIsLoading = true
    // await this.getImeiBindBillList()
    this.initImeiData()
    // this.batchSetProjectList = this.getProjectList()
    this.tableIsLoading = false
  }
}
</script>
