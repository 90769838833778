<!-- 添加授权 -->
<template>
  <div>
    <blockTitle title="添加授权">
      <template slot="customContent">
        <el-button size="mini" class="ml-4" @click="$router.go(-1)"
        >返回设备列表
        </el-button>
      </template>
    </blockTitle>
    <selectBill
        v-if="step === 1"
        ref="selectBill"
        @on-submit="selectBillOnSubmit"
    ></selectBill>
    <importImei
        ref="importImei"
        v-if="step === 2"
        :billInfoObj="stepOneObj"
        @on-re-select-bill="reSelectBill"
    ></importImei>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import selectBill from 'components/client/clientAddLicense/selectBillV2'
import importImei from 'components/client/clientAddLicense/importImeiV2'

export default {
  data () {
    return {
      step: 1,
      stepOneObj: {}
    }
  },
  components: {
    selectBill,
    importImei
  },
  methods: {
    selectBillOnSubmit (params) {
      this.stepOneObj = params
      this.step = 2
    },
    reSelectBill () {
      this.stepOneObj = {}
      this.step = 1
    }
  },
  mounted () {
  },
  beforeRouteLeave (to, from, next) {
    to.meta.isBack = to.name === 'clientDetails'
    next()
  }
}
</script>
